var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ax-list-editor',{attrs:{"items":_vm.domains,"paged-list-config":{ size: 'sm' },"title":("" + (_vm.$t(
      'setup.authentication.connections.identityEmailDomains:heading'
    ))),"valid":_vm.valid},on:{"delete":_vm.removeDomain},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('span',{staticClass:"ax-text-medium"},[_vm._v(" "+_vm._s(_vm.$t( 'setup.authentication.connections.domainsMustBeVerified:description' ))+" ")]),_c('router-link',{attrs:{"to":{ name: 'domainList' }}},[_vm._v(" "+_vm._s(_vm.$t('setup.authentication.connections.viewDomains:button'))+" ")])]},proxy:true},{key:"add",fn:function(){return [_c('v-row',[_c('v-col',[_c('ax-tooltip',{attrs:{"left":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('ax-autocomplete',_vm._g({attrs:{"background-color":"input","dense":"","error-messages":_vm.selectDomainsErrorMessages,"groups":[
                  _vm.$t('setup.authentication.connections.domains:phrase'),
                  _vm.$t(
                    'setup.authentication.connections.currentlyUsedDomains:phrase'
                  ) ],"items":_vm.availableDomainsOptions,"item-text":"name","item-value":"id","multiple":"","outlined":"","required":"","return-object":""},on:{"blur":function($event){return _vm.$v.selectedDomains.$touch()}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t( 'setup.authentication.connections.selectEmailDomains:label' ))+" "),_c('v-icon',{staticClass:"mb-1 ml-2",attrs:{"aria-hidden":"false","aria-label":_vm.$t(
                        'setup.authentication.connections.selectEmailDomains:tooltip'
                      ),"small":""}},[_vm._v(_vm._s(_vm.mdiInformation))])]},proxy:true}],null,true),model:{value:(_vm.selectedDomains),callback:function ($$v) {_vm.selectedDomains=$$v},expression:"selectedDomains"}},on))]}}])},[_vm._v(" "+_vm._s(_vm.$t( 'setup.authentication.connections.selectEmailDomains:tooltip' ))+" ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('ax-button',{attrs:{"mode":"tertiary","data-test-id":"add-domains-submit-button","disabled":!_vm.selectedDomains.length},on:{"click":function($event){return _vm.addDomains(_vm.$v)}}},[_vm._v(_vm._s(_vm.$t('setup.authentication.connections.addDomains:button')))])],1)],1)]},proxy:true},{key:"text",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(item.has_connection)?_c('ax-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"aria-hidden":"false","aria-label":_vm.$t('setup.authentication.connections.domainInUse:tooltip'),"small":"","tabindex":"0"}},on),[_vm._v(_vm._s(_vm.mdiAlert))])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('setup.authentication.connections.domainInUse:tooltip'))+" ")]):_vm._e()]}}])}),(_vm.limit > -1)?_c('ax-modal',{attrs:{"size":"sm"},model:{value:(_vm.modals.maxDomainsNotification.opened),callback:function ($$v) {_vm.$set(_vm.modals.maxDomainsNotification, "opened", $$v)},expression:"modals.maxDomainsNotification.opened"}},[_c('ax-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('span',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(_vm._s(_vm.mdiAlert))]),_c('span',{staticClass:"align-center ml-2"},[_vm._v(_vm._s(_vm.$t('setup.authentication.connections.maxDomains:heading')))])],1),_c('ax-button',{attrs:{"icon":"","aria-title":_vm.$t('general.buttons.close')},on:{"click":function($event){return _vm.modals.maxDomainsNotification.close()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiClose))])],1)],1),_c('v-divider',{staticClass:"mb-3"}),_c('v-card-text',[_vm._t("max-domains-modal-content",function(){return [_c('i18n',{attrs:{"path":"setup.authentication.connections.maxDomains:description"},scopedSlots:_vm._u([{key:"max",fn:function(){return [_vm._v(_vm._s(_vm.limit))]},proxy:true},{key:"link",fn:function(){return [_c('a',{attrs:{"href":"mailto:support@automox.com","target":"_blank"}},[_vm._v("support@automox.com")])]},proxy:true}],null,false,4103519356)})]})],2),_c('v-card-actions',{staticClass:"justify-end px-6 pb-4"},[_c('ax-button',{attrs:{"mode":"primary"},on:{"click":function($event){return _vm.modals.maxDomainsNotification.close()}}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.close'))+" ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }